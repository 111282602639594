// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/utils/is-bot.context.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/utils/is-bot.context.tsx");
  import.meta.hot.lastModified = "1708510956419.2341";
}
// REMIX HMR END

import { createContext, useContext } from "react";
const context = createContext(false);
export function useIsBot() {
  _s();
  return useContext(context) ?? false;
}
_s(useIsBot, "gDsCjeeItUuvgOWf1v4qoK9RF6k=");
export function IsBotProvider({
  isBot,
  children
}) {
  return <context.Provider value={isBot}>{children}</context.Provider>;
}
_c = IsBotProvider;
var _c;
$RefreshReg$(_c, "IsBotProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;