// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$(),
  _s3 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
  import.meta.hot.lastModified = "1728030536059.7163";
}
// REMIX HMR END

// root.tsx
import { Box, Button, ChakraProvider, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text, VStack, cookieStorageManagerSSR, extendTheme, useDisclosure, useToast } from "@chakra-ui/react";
import { withEmotionCache } from "@emotion/react";
import { json } from "@remix-run/node"; // Depends on the runtime you choose
import { Link, Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, isRouteErrorResponse, useFetcher, useLoaderData, useMatches, useNavigate, useRevalidator, useRouteError } from "@remix-run/react";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { userPrefs } from "~/cookies.server";
import { ClientStyleContext, ServerStyleContext } from "./context";
import { cssBundleHref } from "@remix-run/css-bundle";
import { SettingContext } from "./utils/settingUtils";
import { AuthContext, isEditor, useAuth } from "./utils/userUtils";
import { multiSearchQuery } from "./utils/MeiliSearch";
import { Login } from "./components/login";
import { App as CapApp } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { SafeArea } from "capacitor-plugin-safe-area";
import { PushNotifications } from "@capacitor/push-notifications";
import { Device } from "@capacitor/device";
import { useEventSource } from "remix-utils/sse/react";
import { Network } from "@capacitor/network";
import { Dialog } from "@capacitor/dialog";
import { Frontpage } from "./components/frontpage";
import { DealbunnyIcon } from "./components/customIcons";
import { useIsBot } from "./utils/is-bot.context";
import { CapacitorSwipeBackPlugin } from "capacitor-swipe-back-plugin";
import { sendCredit, sendEvent } from "./utils/fetchUtils";
import moment from "moment-timezone";
import MD5 from "crypto-js/md5.js";
export const headers = ({
  loaderHeaders
}) => ({
  "Cache-Control": loaderHeaders.get("Cache-Control") || "max-age=60, s-maxage=120"
});
export const meta = ({
  data
}) => {
  return [{
    title: "dealbunny.de"
  }];
};
export const links = () => cssBundleHref ? [{
  rel: "preconnect",
  href: "https://fonts.googleapis.com"
}, {
  rel: "preconnect",
  href: "https://fonts.gstatic.com"
}, {
  rel: "stylesheet",
  href: "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
}, {
  rel: "stylesheet",
  href: cssBundleHref
}, {
  rel: "preload",
  href: "/assets/login-woman-dealbunny.jpg",
  as: "image",
  type: "image/jpeg"
}] : [{
  rel: "preconnect",
  href: "https://fonts.googleapis.com"
}, {
  rel: "preconnect",
  href: "https://fonts.gstatic.com"
}, {
  rel: "stylesheet",
  href: "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
}];
const Document = _s(withEmotionCache(_c = _s(({
  children
}, emotionCache) => {
  _s();
  const serverStyleData = useContext(ServerStyleContext);
  const clientStyleData = useContext(ClientStyleContext);

  // Only executed on client
  useEffect(() => {
    // re-link sheet container
    emotionCache.sheet.container = document.head;
    // re-inject tags
    const tags = emotionCache.sheet.tags;
    emotionCache.sheet.flush();
    tags.forEach(tag => {
      emotionCache.sheet._insertTag(tag);
    });
    // reset cache to reapply global styles
    clientStyleData?.reset();
  }, []);
  function getColorMode(cookies) {
    const match = cookies.match(new RegExp(`(^| )${CHAKRA_COOKIE_COLOR_KEY}=([^;]+)`));
    return match == null ? void 0 : match[2];
  }

  // here we can set the default color mode. If we set it to null,
  // there's no way for us to know what is the the user's preferred theme
  // so the cient will have to figure out and maybe there'll be a flash the first time the user visits us.
  const DEFAULT_COLOR_MODE = "light";
  const CHAKRA_COOKIE_COLOR_KEY = "chakra-ui-color-mode";
  let data;
  const error = useRouteError();
  if (!isRouteErrorResponse(error) && !(error instanceof Error)) {
    data = useLoaderData();
  }
  let cookies = data?.cookies ? data.cookies : "";
  // the client get the cookies from the document
  // because when we do a client routing, the loader can have stored an outdated value
  if (typeof document !== "undefined") {
    cookies = document.cookie;
  }

  // get and store the color mode from the cookies.
  // It'll update the cookies if there isn't any and we have set a default value
  let colorMode = useMemo(() => {
    let color = getColorMode(cookies);
    if (!color && DEFAULT_COLOR_MODE) {
      cookies += ` ${CHAKRA_COOKIE_COLOR_KEY}=${DEFAULT_COLOR_MODE}`;
      color = DEFAULT_COLOR_MODE;
    }
    return color;
  }, [cookies]);
  const showStatusBar = useCallback(async () => {
    await StatusBar.show();
  }, []);
  const setStatusBarStyleDark = useCallback(async mode => {
    await StatusBar.setStyle({
      style: mode && mode == "dark" ? Style.Dark : Style.Light
    });
    return mode;
  }, []);
  const [isIOS, setIsIOS] = useState(Capacitor.getPlatform() == "ios");
  useEffect(() => {
    if (Capacitor.isNativePlatform() && isIOS) {
      // console.log("Set Status bar", colorMode);
      setStatusBarStyleDark(colorMode).then(mode => {
        // console.log("colorMode", mode);
        showStatusBar();
      });
    }
  }, [colorMode]);

  // 2. Add your color mode config
  const config = {
    initialColorMode: "light",
    useSystemColorMode: true
  };
  const colors = {
    brand: {
      500: "#e91e63"
    },
    pink: {
      "50": "#FDE8EF",
      "100": "#F9BED2",
      "200": "#F594B5",
      "300": "#F06A98",
      "400": "#EC417B",
      "500": "#E8175E",
      "600": "#BA124B",
      "700": "#8B0E38",
      "800": "#5D0926",
      "900": "#2E0513"
    }
  };

  // 2. Update the breakpoints as key-value pairs
  const breakpoints = {
    base: "0px",
    sm: "320px",
    md: "768px",
    lg: "1100px",
    xl: "1400px",
    "2xl": "1536px"
  };
  const theme = extendTheme({
    colors,
    config,
    breakpoints
  });
  useEffect(() => {
    setIsIOS(Capacitor.getPlatform() == "ios");
    CapApp.addListener("appUrlOpen", event => {
      console.log("appUrlOpen", event.url);
      // If no match, do nothing - let regular routing
      // logic take over
    });

    if (Capacitor.isNativePlatform()) {
      if (isIOS) {
        console.log("iOS!");
        SafeArea.getSafeAreaInsets().then(({
          insets
        }) => {
          // console.log(insets);
          for (const [key, value] of Object.entries(insets)) {
            document.documentElement.style.setProperty(`--safe-area-${key}`, `${value}px`);
          }
          document.body.classList.add("ios-safe-area");
        });

        // SafeArea.getStatusBarHeight().then(({ statusBarHeight }) => {
        //   console.log(statusBarHeight, "statusbarHeight");
        // });

        // when safe-area changed
        SafeArea.addListener("safeAreaChanged", data => {
          const {
            insets
          } = data;
          console.log("safeAreaChanged", insets);
          for (const [key, value] of Object.entries(insets)) {
            document.documentElement.style.setProperty(`--safe-area-${key}`, `${value}px`);
          }
        });
      }
    }
    return () => {
      isIOS && SafeArea.removeAllListeners();
    };
  }, [colorMode]);
  const matches = useMatches();
  const match = matches.find(match => match?.data?.ENV?.PUBLIC_SITE_URL);
  const canonical = match?.data?.ENV?.PUBLIC_SITE_URL;
  const matchPath = matches[matches.length - 1];
  const pathname = matchPath?.pathname;
  let isBot = useIsBot();
  return <html lang="de" {...colorMode && {
    "data-theme": colorMode,
    style: {
      colorScheme: colorMode
    }
  }}>
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="facebook-domain-verification" content="tz0zcr723jcfmf7swszlb0oepibrfu" />
          
          <Meta />
          <Links />
          {!!canonical && <link rel="canonical" href={canonical + pathname} />}
          {serverStyleData?.map(({
        key,
        ids,
        css
      }) => <style key={key} data-emotion={`${key} ${ids.join(" ")}`} dangerouslySetInnerHTML={{
        __html: css
      }} />)}

          <style dangerouslySetInnerHTML={{
        __html: `
                  .ios-safe-area {
                    padding-top: var(--safe-area-top);
                    padding-left: var(--safe-area-left);
                    padding-right: var(--safe-area-right);
                    padding-bottom: var(--safe-area-bottom);
                  } 
                  .ios-safe-area .chakra-modal__content {
                    top: var(--safe-area-top) !important;
                  }
                  
                  .ios-safe-area .navigation-fixed {
                    padding-top: var(--safe-area-top) !important;
                    padding-bottom: 10px;
                  }
                  .ios-safe-area .navigation-fixed-bg {
                    height: var(--safe-area-top) !important;
                  }
                  body.ios-safe-area {
                    overscroll-behavior: none;
                    overflow : scroll
                  }`
      }} />
        </head>
        <body {...colorMode && {
      className: `chakra-ui-${colorMode}`
    }}>
          <ChakraProvider colorModeManager={cookieStorageManagerSSR(cookies)} theme={theme}>
            {children}
          </ChakraProvider>
          <ScrollRestoration />
          {data?.ENV && <script dangerouslySetInnerHTML={{
        __html: `window.ENV = ${JSON.stringify(data?.ENV)}`
      }} />}
          {isBot ? null : <Scripts />}
          <LiveReload />
        </body>
      </html>;
}, "SEzGUECYiy5DE1NSUuNRlU9+RGQ=", false, function () {
  return [useRouteError, useLoaderData, useMatches, useIsBot];
})), "SEzGUECYiy5DE1NSUuNRlU9+RGQ=", false, function () {
  return [useRouteError, useLoaderData, useMatches, useIsBot];
});
_c2 = Document;
export const loader = async ({
  request
}) => {
  const filteredEnv = Object.keys(process.env).reduce((acc, key) => {
    if (key.startsWith("PUBLIC_")) {
      acc[key] = process.env[key];
    }
    return acc;
  }, {});
  const cookieHeader = request.headers.get("Cookie");
  const cookie = (await userPrefs.parse(cookieHeader)) || {};
  const filterPromo = `type = "logo" AND publish = true`;
  const hits = await multiSearchQuery([{
    indexUid: "setting",
    sort: [],
    filter: `public = true AND (target = "public" ${cookie?.user?.role ? `OR target = "${cookie.user.role}"` : ""})`,
    limit: 100
  }, {
    indexUid: "promotion",
    sort: ["position:asc", "createdAt:desc"],
    filter: filterPromo,
    limit: 100
  }]);
  const promoTheme = hits && hits[1] && hits[1].hits?.map(p => {
    // console.log("between", moment(p.startDate).year(moment().year()), moment(p.endDate).year(moment().year()))
    if (moment().isBetween(moment(p.startDate).year(moment().year()), moment(p.endDate).year(moment().year()), "h")) {
      return p;
    }
    return null;
  }).filter(p => !!p);
  return json({
    cookies: request.headers.get("cookie") ?? "",
    settings: hits && hits[0].hits,
    user: cookie.user,
    promoTheme,
    ENV: {
      ...filteredEnv,
      PUBLIC_REACT_APP_VERSION: process.env.npm_package_version
    }
  }, {
    status: 200,
    headers: {
      "Cache-Control": cookie.user ? "max-age=5, s-maxage=10" : "max-age=600, s-maxage=1200"
    }
  });
};
export function shouldRevalidate({
  currentParams,
  nextParams,
  formMethod,
  currentUrl,
  nextUrl,
  defaultShouldRevalidate
}) {
  const url = new URL(currentUrl);
  const urlNext = new URL(nextUrl);
  // console.log("shouldRevalidate", currentUrl, nextUrl)
  if (urlNext.searchParams.get("page") && Number(url.searchParams.get("page") || 1) != Number(urlNext.searchParams.get("page") || 1)) {
    // console.log("shouldRevalidate Page", false)
    return false;
  }

  // console.log("shouldRevalidate", currentParams, nextParams)
  if (Object.keys(currentParams).length == 0 && "*" in nextParams) {
    // console.log("shouldRevalidate Params", false)
    return false;
  }

  // console.log("shouldRevalidate", defaultShouldRevalidate)
  return defaultShouldRevalidate;
}
export default function App() {
  _s2();
  const data = useLoaderData();
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure();
  // console.log(data);
  const defaultUser = data.user?.authToken ? data.user : null;
  const [user, setUser] = useState(defaultUser);
  const [tokenSaved, setTokenSaved] = useState(false);
  const [settings, setSettings] = useState([...data.settings]);
  const {
    login
  } = useAuth();
  const [buildVersion, setBuildVersion] = useState(data.ENV.PUBLIC_BUILD_HASH);
  CapacitorSwipeBackPlugin.enable();
  useEffect(() => {
    if (buildVersion != data.ENV.PUBLIC_BUILD_HASH) {
      document.location.reload();
    }
  }, [data.ENV.PUBLIC_BUILD_HASH]);
  useEffect(() => {
    setSettings([...data.settings, {
      name: "capacitor",
      data: {
        plattform: Capacitor.getPlatform()
      }
    }, {
      name: "promoTheme",
      data: data.promoTheme
    }]);
  }, [data.settings]);
  const fetcher = useFetcher();
  useEffect(() => {
    if (fetcher.data) {
      const deviceData = fetcher.data;
      deviceData?.user && login(deviceData.user);
    }
  }, [fetcher.data]);
  const navigate = useNavigate();
  const addListeners = async () => {
    await PushNotifications.removeAllListeners();
    await PushNotifications.addListener("registration", async token => {
      if (user && token.value && !tokenSaved) {
        setTokenSaved(true);
        localStorage && localStorage.setItem("pushToken", token.value);
        const userTokens = user?.pushs?.findIndex(push => token.value.startsWith(push.pushToken));
        if (userTokens == -1) {
          const info = await Device.getInfo();
          fetcher.submit({
            token,
            info
          }, {
            method: "post",
            encType: "application/json",
            action: "/api/device"
          });
        }
      }
    });
    await PushNotifications.addListener("registrationError", err => {
      console.error("Registration error: ", err.error);
    });
    await PushNotifications.addListener("pushNotificationReceived", notification => {
      console.log("Push notification received: ", notification);
    });
    await PushNotifications.addListener("pushNotificationActionPerformed", notification => {
      console.log("Push notification action performed", notification);
      if (notification.notification.data?.path) {
        navigate(notification.notification.data.path);
      }
    });
  };
  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === "prompt") {
      permStatus = await PushNotifications.requestPermissions();
      if (permStatus.receive === "granted") {
        await addListeners().catch(console.error);
      }
    }
    if (permStatus.receive !== "granted") {
      throw new Error("User denied permissions!");
    }

    // TESTING
    // await addListeners().catch(console.error);
    await PushNotifications.register();
  };
  const getDeliveredNotifications = async () => {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log("delivered notifications", notificationList);
  };
  const [initialRenderComplete, setInitialRenderComplete] = useState(false);
  const revalidator = useRevalidator();
  const showConfirm = async () => {
    const {
      value
    } = await Dialog.confirm({
      title: "Keine Internetverbindung",
      message: `App neu laden?`
    });
    console.log("Confirmed:", value);
    if (value) {
      revalidator.revalidate();
    }
  };
  useEffect(() => {
    // Only executed on client
    setInitialRenderComplete(true);
    // console.log("setInitialRenderComplete", true);
    if (Capacitor.isNativePlatform() && user) {
      addListeners().catch(console.error);
    }
    Network.addListener("networkStatusChange", status => {
      console.log("Network status changed", status);
      if (!status.connected && Capacitor.isNativePlatform()) {
        showConfirm();
      }
    });
    CapApp.addListener('backButton', ({
      canGoBack
    }) => {
      if (!canGoBack) {
        CapApp.exitApp();
      } else {
        window.history.back();
      }
    });
  }, []);
  useEffect(() => {
    if (Capacitor.isNativePlatform() && user) {
      registerNotifications().catch(console.error);
    }
  }, [user]);
  const lastPipelineMessage = useEventSource(`${data.ENV.PUBLIC_RAW_SITE_URL}/sse/message`, {
    event: "message-data"
  });
  const toast = useToast();
  useEffect(() => {
    if (lastPipelineMessage && user && isEditor(user)) {
      (async () => {
        const lastObj = JSON.parse(lastPipelineMessage ? lastPipelineMessage : "null");
        console.log("message", lastObj);
        if (lastObj.key == "comment-count") {
          toast({
            title: `${lastObj.value} neue Kommentare`,
            variant: "left-accent",
            isClosable: true,
            position: "top-right",
            status: "info"
          });
        }
        if (lastObj.key == "comment-content") {
          const commentData = JSON.parse(lastObj.value);
          if (commentData.editor != user.id) toast({
            title: `Neuer Kommentar`,
            description: <Box>
                  <Link to={"/deal/" + commentData.link + "#comment-" + commentData.id}>
                    <Box noOfLines={1}>
                      <Text fontStyle={"normal"} fontWeight={"bold"} display={"inline-block"} mr={2}>
                        Deal:
                      </Text>
                      {commentData.deal}
                    </Box>
                    <Box fontStyle={"italic"} display={"inline-block"} noOfLines={6}>
                      <Text fontStyle={"normal"} fontWeight={"bold"} display={"inline-block"} mr={2}>
                        {commentData.username}:
                      </Text>
                      {commentData.content}
                    </Box>
                  </Link>
                </Box>,
            variant: "left-accent",
            isClosable: true,
            position: "top-right",
            status: "info"
          });
        }
      })().catch(e => console.log(e));
    }
  }, [lastPipelineMessage]);
  async function generateRandomHash() {
    // Generate a random array of 16 bytes
    const randomValues = window.crypto.getRandomValues(new Uint8Array(16));

    // Hash the random values using SHA-256
    if (crypto.subtle) {
      const hashBuffer = await crypto.subtle.digest('SHA-256', randomValues);

      // Convert the hash to a hex string
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      return hashHex;
    } else {
      return MD5(String(randomValues)).toString();
    }
  }
  let timer = undefined;
  async function sendPing() {
    const lastPing = localStorage && localStorage.getItem("lastPing");
    const diff = lastPing && moment().diff(moment(lastPing), "s") || 0;
    if (diff >= 60 * 5 || lastPing === null) {
      // Get the current time using moment()
      var now = moment();

      // Round the minutes to the nearest 5
      var roundedMinutes = 5 * Math.round(now.minutes() / 5);

      // Set the minutes to the rounded value and seconds to 0 for precision
      now.minutes(roundedMinutes).seconds(0);

      // console.log(now.format("YYYY-MM-DD HH:mm:ss"));

      let pingHash = localStorage && localStorage.getItem("pingHash") || undefined;
      if (!pingHash) {
        pingHash = await generateRandomHash();
        localStorage && localStorage.setItem("pingHash", String(pingHash));
      }
      sendEvent({
        id: user ? user.id : pingHash,
        event: "ping",
        tag: Capacitor.getPlatform(),
        timestamp: now.unix() * 10 ** 3
      });
      localStorage && localStorage.setItem("lastPing", moment().toISOString());
    }
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      sendPing();
    }, 1000 * 60 * 5);
  }
  useEffect(() => {
    const listener = CapApp.addListener("appStateChange", ({
      isActive
    }) => {
      console.log("Ping. Is active?", isActive);
      if (isActive) {
        timer && clearTimeout(timer);
        sendPing();
        sendCredit({
          action: "view"
        });
      } else {
        timer && clearTimeout(timer);
      }
    });
    timer && clearTimeout(timer);
    sendPing();
    sendCredit({
      action: "view"
    });
    return () => {
      timer && clearTimeout(timer);
      listener.then(l => l.remove());
    };
  }, []);
  return <Document>
      <SettingContext.Provider value={{
      settings,
      setSettings
    }}>
        <AuthContext.Provider value={{
        user,
        setUser,
        onOpen
      }}>
          {initialRenderComplete && <Outlet />}
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent mx={5} w={"-webkit-fill-available"}>
              <ModalCloseButton />
              <ModalBody>
                <Login isModal={true} onClose={onClose} />
              </ModalBody>
            </ModalContent>
          </Modal>
        </AuthContext.Provider>
      </SettingContext.Provider>
    </Document>;
}
_s2(App, "ixfeeCALflaYEta/PaCLKGbIloE=", false, function () {
  return [useLoaderData, useDisclosure, useAuth, useFetcher, useNavigate, useRevalidator, useEventSource, useToast];
});
_c3 = App;
export function ErrorBoundary() {
  _s3();
  const error = useRouteError();
  let subject = "";
  let stack = "";
  if (isRouteErrorResponse(error)) {
    subject = `${error.status} ${error.statusText}`;
    stack = error.data;
  } else if (error instanceof Error) {
    subject = `${error.message}`;
    stack = error.stack || "";
  }
  return <Document>
      <Frontpage isPullable={false} header={<VStack w={"100%"} p={10}>
            <Link to="/">
              <Button variant={"unstyled"} pr="3" bgColor={"transparent"} minWidth={200} maxWidth={250} aria-label={"dealbunny Logo"}>
                <DealbunnyIcon w="100%" h="100%" />
              </Button>
            </Link>
          </VStack>} main={<VStack w={"100%"} h={"100vh"} p={10}>
            <Heading textAlign={"center"}>
              {isRouteErrorResponse(error) ? `${error.status} ${error.statusText}` : error instanceof Error ? error.message : "Unknown Error"}
            </Heading>
            <Box>
              Wir haben leider ein Problem mit der Seite. Benötigst du hilfe?
              Schreib uns eine{" "}
              <Link to={`mailto:support@dealbunny.de?subject=Error: ${subject}`}>
                <Text decoration={"underline"} display={"inline"}>
                  Mail
                </Text>
              </Link>
              .
            </Box>
            <Box>
              <Button variant={"solid"} onClick={() => {
          window.location.pathname = "/";
        }}>
                zurück zur Startseite
              </Button>
            </Box>
          </VStack>} footer={<></>} />
    </Document>;
}
_s3(ErrorBoundary, "oAgjgbJzsRXlB89+MoVumxMQqKM=", false, function () {
  return [useRouteError];
});
_c4 = ErrorBoundary;
var _c, _c2, _c3, _c4;
$RefreshReg$(_c, "Document$withEmotionCache");
$RefreshReg$(_c2, "Document");
$RefreshReg$(_c3, "App");
$RefreshReg$(_c4, "ErrorBoundary");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;